<template>
  <div class="wrap">
    <div class="view-content">
      <div class="view-info">
        <div class="avatar">
          <img
            :src="info.img_host + info.img_path"
            width="100%"
            height="100%"
          />
        </div>
        <div class="info-text">
          <p class="title">{{ info.name }}</p>
          <p style="margin-top: 10px">英文名称：{{ info.en_name }}</p>
          <p>
            规格：{{ info.specifications == "" ? "无" : info.specifications }}
          </p>
          <p>口味：{{ info.flavor == "" ? "无" : info.flavor }}</p>
          <p>厂家：{{ info.manufacturer == "" ? "无" : info.manufacturer }}</p>
        </div>
      </div>
      <div class="view-card" style="margin-bottom: 20px">
        <div class="view-card-list">
          <div class="view-card-list-item">
            <div class="label">食用方法：</div>
            <div class="value">
              {{ info.use_method == "" ? "无" : info.use_method }}
            </div>
          </div>
          <div class="view-card-list-item">
            <div class="label">适用场景：</div>
            <div class="value">
              {{ info.scenes == "" ? "无" : info.scenes }}
            </div>
          </div>
          <div class="view-card-list-item">
            <div class="label">主要配料：</div>
            <div class="value">
              {{ info.ingredients == "" ? "无" : info.ingredients }}
            </div>
          </div>
          <div class="view-card-list-item">
            <div class="label jian-a">产品简介：</div>
            <div class="value jian-b" v-html="info.synopsis">
              {{ info.synopsis == "" ? "无" : info.synopsis }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "View.vue",
  data() {
    return {
      activeName: "1",
      editorContent: "",
      imageFile: null,
      editor: null,
      info: {},
      timeOptions: [],
      weightOPtions: [],
      employOPtions: [],
      weitStr: "",
      numberStr: "",
      id: "",
      image: this.$baseURL, //图片路径
    };
  },
  created() {
    this.useTimeList();
    this.id = this.$route.query.uuid;

    this.getData();
  },
  computed: {},
  watch: {
    weightOPtions: {
      deep: true,
      handler(newVal) {
        newVal.map((item) => {
          if (item.id == Number(this.info.use_weight_unit))
            this.weitStr = item.item;
        });
      },
    },
    employOPtions: {
      deep: true,
      handler(newVal) {
        newVal.map((item) => {
          if (item.id == Number(this.info.use_number_unit))
            this.numberStr = item.item;
        });
      },
    },
  },
  mounted() {},
  methods: {
    getData(id) {
      this.info = {};
      let url = "/p/nutrition/read";
      this.$axios
        .post(url, this.$qs({ uuid: this.$route.query.uuid }))
        .then((response) => {
          if (response.data.code === 0) {
            this.info = response.data.data;
            this.info.use_time = this.handleShowTime(this.info.use_time);
          } else {
            this.$message({
              message: response.message,
              type: "error",
            });
          }
        });
    },
    useTimeList() {},
    handleShowTime(vals) {
      // vals "6,4,1"
      if (vals != "" && vals != undefined) {
        let list = vals.split(","); // [6,4,1]
        let tempArray = [];
        list.map((val) => {
          this.timeOptions.map((item) => {
            if (item.uuid == val) {
              tempArray.push(item.name);
            }
          });
        });
        return tempArray.join(",");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.view-content
  .view-card
  .view-card-list
  .view-card-list-item
  .value[data-v-c6390fcc] {
}
.wrap {
  background-color: #fff;
}
.view-content {
  .view-info {
    padding-left: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    // margin-top: 30px;
    padding-top: 40px;
    padding-right: 30px;

    .avatar {
      margin: 0 30px;
      width: 162px;
      height: 162px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f7fa;
      border-radius: 5px;
      overflow: hidden;
      box-sizing: border-box;
      border: 1px solid #eee;

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .info-text {
      padding-left: 30px;
      .title {
        font-size: 26px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 35px;
      }

      p {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 30px;
      }
    }
  }

  .view-card {
    margin: 10px 30px;
    padding: 20px 30px 10px;
    box-shadow: 0px 0px 4px 3px rgba(237, 237, 237, 0.5);
    border-radius: 8px;

    .view-card-title {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #414141;
      height: 30px;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        width: 5px;
        height: 18px;
        border-radius: 4px;
        background: #2590e7;
        display: inline-block;
        margin-right: 7px;
      }

      span {
        padding-left: 10px;
        font-size: 14px;
        color: #999;
      }
    }

    .view-card-list {
      .view-card-list-item {
        padding: 10px 0;
        display: flex;
        border-bottom: 1px dashed #ccc;

        &:last-child {
          border-bottom: none;
        }
        .label {
          width: 150px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }

        .value {
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
  // /deep/.view-content
  //   .view-card
  //   .view-card-list
  //   .view-card-list-item
  //   .value[data-v-c6390fcc][data-v-c6390fcc] {
  //   color: #666666;
  //   font-size: 18px;
  // }
}
.jian-b {
  width: 1200px;
  height: 100%;
}
</style>
